/* @import "~react-image-gallery/styles/css/image-gallery.css"; */
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* user-select: none; */
	min-height: 100%;
	background-color: "#123123ff";
	overflow: hidden;
}

#root {
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

@font-face {
	font-family: "양진체";
	src: url("https://cdn.jsdelivr.net/gh/supernovice-lab/font@0.9/yangjin.woff")
		format("woff");
	font-weight: normal;
	font-style: normal;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox  */
input[type="number"] {
	-moz-appearance: textfield;
}
