* {
	box-sizing: border-box;
}
body {
	margin: 0;
	background: #eaedf1;
	font-family: "Lato", sans-serif;
}
.header {
	background: white;
	padding: 30px;
	text-align: center;
}
.header h1 {
	font-weight: 300;
	display: inline;
}
h2 {
	color: #89867e;
	text-align: center;
	font-weight: 300;
}
.color {
	width: 350px;
	margin: 0 auto;
}
.color li {
	margin: 0 15px 0 0;
	width: 30px;
	height: 30px;
	display: inline-block;
	border-radius: 100%;
}
.color .red {
	background: #fa5a5a;
}
.color .yellow {
	background: #f0d264;
}
.color .orange {
	background: #ffab45;
}
.color .green {
	background: #82c8a0;
}
.color .cyan {
	background: #7fccde;
}
.color .blue {
	background: #6698cb;
}
.color .purple {
	background: #cb99c5;
}

.content,
.content-gradient,
.content-3d {
	margin: 40px auto;
}
.content {
	width: 80%;
	max-width: 700px;
}
.content-3d {
	width: 50%;
	max-width: 300px;
}
pre {
	width: 100%;
	padding: 30px;
	background-color: rgba(0, 0, 0, 0.72);
	color: #f8f8f2;
	border-radius: 0 0 4px 4px;
	margin-top: 20px;
	white-space: pre-wrap; /* css-3 */
	white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
	white-space: -pre-wrap; /* Opera 4-6 */
	white-space: -o-pre-wrap; /* Opera 7 */
	word-wrap: break-word; /* Internet Explorer 5.5+ */
}
pre .bt {
	color: #f8f8f2;
} /* <> */
pre .anc {
	color: #f92672;
} /* anchor tag */
pre .att {
	color: #a6a926;
} /* attribute */
pre .val {
	color: #e6db74;
} /* value */

.btn-container,
.container {
	background-color: white;
	border-radius: 4px;
	text-align: center;
	margin-bottom: 40px;
}
.container h2 {
	padding-top: 30px;
	font-weight: 300;
}
.btn,
.btn-two {
	margin: 9px;
}
.btn-gradient {
	margin: 5px;
}
a[class*="btn"] {
	text-decoration: none;
}
input[class*="btn"],
button[class*="btn"] {
	border: 0;
}

/* Here you can change the button sizes */
.btn.large,
.btn-two.large,
.btn-effect.large {
	padding: 20px 40px;
	font-size: 22px;
}
.btn.small,
.btn-two.small,
.btn-gradient.small,
.btn-effect.small {
	padding: 8px 18px;
	font-size: 14px;
}
.btn.mini,
.btn-two.mini,
.btn-gradient.mini,
.btn-effect.mini {
	padding: 4px 12px;
	font-size: 12px;
}
.btn.block,
.btn-two.block,
.btn-gradient.block,
.btn-effect.block {
	display: block;
	width: 60%;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}
.btn-gradient.large {
	padding: 15px 45px;
	font-size: 22px;
}

/* Colors for .btn and .btn-two */
.btn.blue,
.btn-two.blue {
	background-color: #7fb1bf;
}
.btn.green,
.btn-two.green {
	background-color: #9abf7f;
}
.btn.red,
.btn-two.red {
	background-color: #fa5a5a;
}
.btn.purple,
.btn-two.purple {
	background-color: #cb99c5;
}
.btn.cyan,
.btn-two.cyan {
	background-color: #7fccde;
}
.btn.yellow,
.btn-two.yellow {
	background-color: #f0d264;
}
.btn-two.orange {
	background-color: #ffab45;
}
.btn-two.black {
	background-color: #717171;
}
.btn-two.white {
	background-color: #c8c8c8;
}

.rounded {
	border-radius: 10px;
}

/* default button style */
.btn {
	position: relative;
	border: 0;
	padding: 15px 25px;
	display: inline-block;
	text-align: center;
	color: white;
}
.btn:active {
	top: 4px;
}

/* color classes for .btn */
.btn.blue {
	box-shadow: 0px 4px #74a3b0;
}
.btn.blue:active {
	box-shadow: 0 0 #74a3b0;
	background-color: #709ca8;
}

.btn.green {
	box-shadow: 0px 4px 0px #87a86f;
}
.btn.green:active {
	box-shadow: 0 0 #87a86f;
	background-color: #87a86f;
}

.btn.red {
	box-shadow: 0px 4px 0px #e04342;
}
.btn.red:active {
	box-shadow: 0 0 #ff4c4b;
	background-color: #ff4c4b;
}

.btn.purple {
	box-shadow: 0px 4px 0px #ad83a8;
}
.btn.purple:active {
	box-shadow: 0 0 #ba8cb5;
	background-color: #ba8cb5;
}

.btn.cyan {
	box-shadow: 0px 4px 0px #73b9c9;
}
.btn.cyan:active {
	box-shadow: 0 0 #73b9c9;
	background-color: #70b4c4;
}

.btn.yellow {
	box-shadow: 0px 4px 0px #d1b757;
}
.btn.yellow:active {
	box-shadow: 0 0 #ff4c4b;
	background-color: #d6bb59;
}

/* Button two - I have no creativity for names */
.btn-two {
	color: white;
	padding: 15px 25px;
	display: inline-block;
	border: 1px solid rgba(0, 0, 0, 0.21);
	border-bottom-color: rgba(0, 0, 0, 0.34);
	text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 1px 0 rgba(255, 255, 255, 0.34) inset,
		0 2px 0 -1px rgba(0, 0, 0, 0.13), 0 3px 0 -1px rgba(0, 0, 0, 0.08),
		0 3px 13px -1px rgba(0, 0, 0, 0.21);
}
.btn-two:active {
	top: 1px;
	border-color: rgba(0, 0, 0, 0.34) rgba(0, 0, 0, 0.21) rgba(0, 0, 0, 0.21);
	box-shadow: 0 1px 0 rgba(255, 255, 255, 0.89), 0 1px rgba(0, 0, 0, 0.05) inset;
	position: relative;
}

body {
	/* font-family: "Josefin Slab", serif; */
	font-size: 20px;
	line-height: 24px;
	color: #333;
	background: #ececec;
	overflow-y: scroll;
}

p {
	margin-bottom: 0.5em;
}

a,
a:visited {
	text-decoration: none;
	color: #00ae68;
}

.clear {
	clear: both;
}

.pageTitle {
	font-size: 2em;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 1em;
	color: #00ae68;
}

.pageSubTitle {
	margin-bottom: 0.5em;
	font-size: 1.3em;
	font-weight: 700;
	line-height: 1em;
	color: #222;
}

.articleTitle {
	font-size: 1.15em;
	font-weight: 700;
	line-height: 1em;
	color: #222;
}

.wrapper {
	width: 600px;
	margin: 20px auto;
}

.container {
	padding-top: 1em;
	margin-top: 1em;
	border-top: solid 1px #ccc;
}

a.button {
	display: block;
	position: relative;
	float: left;
	width: 120px;
	padding: 0;
	margin: 10px 20px 10px 0;
	font-weight: 600;
	text-align: center;
	line-height: 50px;
	color: #fff;
	border-radius: 5px;
	transition: all 0.2s;
}

.btnBlueGreen {
	background: #00ae68;
}

.btnLightBlue {
	background: #5dc8cd;
}

.btnOrange {
	background: #ffaa40;
}

.btnPurple {
	background: #a74982;
}

/* FADE */
.btnFade.btnBlueGreen:hover {
	background: #21825b;
}

.btnFade.btnLightBlue:hover {
	background: #01939a;
}

.btnFade.btnOrange:hover {
	background: #ff8e00;
}

.btnFade.btnPurple:hover {
	background: #6d184b;
}

/* 3D */
.btnBlueGreen.btnPush {
	box-shadow: 0px 5px 0px 0px #007144;
}

.btnLightBlue.btnPush {
	box-shadow: 0px 5px 0px 0px #1e8185;
}

.btnOrange.btnPush {
	box-shadow: 0px 5px 0px 0px #a66615;
}

.btnPurple.btnPush {
	box-shadow: 0px 5px 0px 0px #6d184b;
}

.btnPush:hover {
	margin-top: 15px;
	margin-bottom: 5px;
}

.btnBlueGreen.btnPush:hover {
	box-shadow: 0px 0px 0px 0px #007144;
}

.btnLightBlue.btnPush:hover {
	box-shadow: 0px 0px 0px 0px #1e8185;
}

.btnOrange.btnPush:hover {
	box-shadow: 0px 0px 0px 0px #a66615;
}

.btnPurple.btnPush:hover {
	box-shadow: 0px 0px 0px 0px #6d184b;
}

/* BORDER */
.btnBlueGreen.btnBorder {
	box-shadow: 0px 0px 0px 0px #21825b;
}

.btnBlueGreen.btnBorder:hover {
	box-shadow: 0px 0px 0px 5px #21825b;
}

.btnLightBlue.btnBorder {
	box-shadow: 0px 0px 0px 0px #01939a;
}

.btnLightBlue.btnBorder:hover {
	box-shadow: 0px 0px 0px 5px #01939a;
}

.btnOrange.btnBorder {
	box-shadow: 0px 0px 0px 0px #a66615;
}

.btnOrange.btnBorder:hover {
	box-shadow: 0px 0px 0px 5px #a66615;
}

.btnPurple.btnBorder {
	box-shadow: 0px 0px 0px 0px #6d184b;
}

.btnPurple.btnBorder:hover {
	box-shadow: 0px 0px 0px 5px #6d184b;
}

/* FLOAT */
.btnFloat {
	background: none;
	box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
}

.btnFloat:before {
	content: "Float";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 120px;
	height: 50px;
	border-radius: 5px;
	transition: all 0.2s;
}

.btnBlueGreen.btnFloat:before {
	background: #00ae68;
}

.btnLightBlue.btnFloat:before {
	background: #5dc8cd;
}

.btnOrange.btnFloat:before {
	background: #ffaa40;
}

.btnPurple.btnFloat:before {
	background: #8d336a;
}

.btnFloat:before {
	box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.4);
}

.btnFloat:hover:before {
}

.btnFloat:hover:before {
	margin-top: -2px;
	margin-left: 0px;
	transform: scale(1.1, 1.1);
	-ms-transform: scale(1.1, 1.1);
	-webkit-transform: scale(1.1, 1.1);
	box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.25);
}

/* SLIDE */
.btnSlide.btnBlueGreen {
	background: 0;
}

.btnSlide .top {
	position: absolute;
	top: 0px;
	left: 0;
	width: 120px;
	height: 50px;
	background: #00ae68;
	z-index: 10;
	transition: all 0.2s;
	border-radius: 5px;
}

.btnSlide.btnBlueGreen .top {
	background: #00ae68;
}

.btnSlide.btnLightBlue .top {
	background: #5dc8cd;
}

.btnSlide.btnOrange .top {
	background: #ffaa40;
}

.btnSlide.btnPurple .top {
	background: #a74982;
}

.btnSlide .bottom {
	position: absolute;
	top: 0;
	left: 0;
	width: 120px;
	height: 50px;
	color: #000;
	z-index: 5;
	border-radius: 5px;
}

.btnSlide:hover .top {
	top: 40px;
}
